import { Indexable } from "./GlobalColors.const";

export const Models:Indexable = {
    "1"	:"EMRAM",
    "2"	:"O-EMRAM",
    "3"	:"AMAM",
    "4"	:"INFRAM",
    "5"	:"CISOM",
    "6"	:"DIAM",
    "7"	:"CCMM",
    "8"	:"DHI",
    "9"	:"DHI Short",
    "10":"GDHP",
    "11":"DHI-CCMM",
    "12":"EMRAM 2022",
    "13":"DigitalRadar",
    "14":"C-COMM",
    "15":"INFRAM 2024",
    "16":"AMAM 2024",
}