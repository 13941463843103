import { OpportunitiesTable } from "./Components/Tables/Opportunities.component";
import { SoftwareApplicationsTable } from "./Components/Tables/SoftwareApplications.component";
import { PerformanceIndicatorsTable } from "./Components/Tables/PerformanceIndicators.component";
import { AssessmentOverviewTable } from "./Components/Tables/AssessmentOverview.component";
import { ColorLegendTable } from "./Components/Tables/ColorLegend.component";
import { FocusAreaTable } from "./Components/Tables/FocusArea.component";
import { DataCaptureTable } from "./Components/Tables/DataCapture.component";
import { PatientEngagementTable } from "./Components/Tables/PatientEngagement.component";
import { ColorLegend2Table } from "./Components/Tables/ColorLegend2.component";

import {
    Heading1,
    BasicText,
    HeadingWithPreStyling,
} from "./Components/Text.component";
import { UnorderedList, HorizontalList } from "./Components/List.component";
import { Box, Stack } from "@mui/material";
import { ApiBaseRoute } from "../../api_helper";
import {
    AARRequest,
    ReportSectionOuter,
    ReportSectionItem,
} from "./AARTemplateBuilder.model";
import { preStyledHeadings, preStyledImages } from "./Components/PreStying.component";
import { ImageWithPreStyling } from "./Components/Image.component";
import { Gauge, gaugeClasses } from "@mui/x-charts";
import { StagelessOpportunitiesTable } from "./Components/Tables/StagelessOpportunities.component";

export const GetReportDataExport = (request: AARRequest, token: string|undefined):Promise<Response> => {
    
    return fetch(ApiBaseRoute + `ReportData/ExportReportData?modelID=${request.modelID}&uniqueID=${request.uniqueID}&languageID=${request.languageID}`, {
        method: "GET",
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/octet-stream"
        },
    });
}

export const GetReportData = (
    request: AARRequest,
    token: string | undefined
): Promise<Response> => {
    var uri = ApiBaseRoute +
        `ReportData/GetReportData?uniqueID=${request.uniqueID}&modelID=${request.modelID}`
    if (request.languageID)
    uri = ApiBaseRoute +
        `ReportData/GetReportData?uniqueID=${request.uniqueID}&modelID=${request.modelID}&languageID=${request.languageID}`

    return fetch(uri
       ,
        {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                "content-type": "application/json",
                accept: "application/json",
            },
        }
    );
};

export const sectionBuilder = (section: ReportSectionOuter, index: number) => {
    return (
        <Stack
            key={`sectionWrap-` + index}
            sx={{ width: "100%", marginTop: "20px", marginBottom: "20px" }}
        >
            {section.top && (
                <Stack direction="row" key={`topWrap-` + index}>
                    <Box key={`topLeft-` + index}>
                        {section.top.left !== undefined &&
                            section.top.left.map(
                                (
                                    sectionItem: ReportSectionItem,
                                    index: number
                                ) => {
                                    return sectionItemBuilder(
                                        sectionItem,
                                        index
                                    );
                                }
                            )}
                    </Box>
                    <Box key={`topCenter-` + index} sx={{ margin: "0 auto" }}>
                        {section.top.center !== undefined &&
                            section.top.center.map(
                                (
                                    sectionItem: ReportSectionItem,
                                    index: number
                                ) => {
                                    return sectionItemBuilder(
                                        sectionItem,
                                        index
                                    );
                                }
                            )}
                    </Box>
                    <Box key={`topRight-` + index}>
                        {section.top.right !== undefined &&
                            section.top.right.map(
                                (
                                    sectionItem: ReportSectionItem,
                                    index: number
                                ) => {
                                    return sectionItemBuilder(
                                        sectionItem,
                                        index
                                    );
                                }
                            )}
                    </Box>
                </Stack>
            )}
            {section.middle && (
                <Stack direction="row" key={`middleWrap-` + index}>
                    <Box key={`middleLeft-` + index}>
                        {section.middle.left !== undefined &&
                            section.middle.left.map(
                                (
                                    sectionItem: ReportSectionItem,
                                    index: number
                                ) => {
                                    return sectionItemBuilder(
                                        sectionItem,
                                        index
                                    );
                                }
                            )}
                    </Box>
                    <Box
                        key={`middleCenter-` + index}
                        sx={{ margin: "0 auto" }}
                    >
                        {section.middle.center !== undefined &&
                            section.middle.center?.map(
                                (
                                    sectionItem: ReportSectionItem,
                                    index: number
                                ) => {
                                    return sectionItemBuilder(
                                        sectionItem,
                                        index
                                    );
                                }
                            )}
                    </Box>
                    <Box key={`middleRight-` + index}>
                        {section.middle.right !== undefined &&
                            section.middle.right.map(
                                (
                                    sectionItem: ReportSectionItem,
                                    index: number
                                ) => {
                                    return sectionItemBuilder(
                                        sectionItem,
                                        index
                                    );
                                }
                            )}
                    </Box>
                </Stack>
            )}
            {section.bottom && (
                <Stack
                    direction="row"
                    key={`bottomWrap-` + index}
                    sx={{ width: "100%" }}
                >
                    <Box key={`bottomLeft-` + index}>
                        {section.bottom.left !== undefined &&
                            section.bottom.left.map(
                                (
                                    sectionItem: ReportSectionItem,
                                    index: number
                                ) => {
                                    return sectionItemBuilder(
                                        sectionItem,
                                        index
                                    );
                                }
                            )}
                    </Box>
                    <Box
                        key={`bottomCenter-` + index}
                        sx={{ margin: "0 auto" }}
                    >
                        {section.bottom.center !== undefined &&
                            section.bottom.center?.map(
                                (
                                    sectionItem: ReportSectionItem,
                                    index: number
                                ) => {
                                    return sectionItemBuilder(
                                        sectionItem,
                                        index
                                    );
                                }
                            )}
                    </Box>
                    <Box key={`bottomRight-` + index}>
                        {section.bottom.right !== undefined &&
                            section.bottom.right.map(
                                (
                                    sectionItem: ReportSectionItem,
                                    index: number
                                ) => {
                                    return sectionItemBuilder(
                                        sectionItem,
                                        index
                                    );
                                }
                            )}
                    </Box>
                </Stack>
            )}
        </Stack>
    );
};

const sectionItemBuilder = (item: ReportSectionItem, index: number) => {
    switch (item.type) {
        case "image": {
            let styleOverride = item.data.text[0].style
                ? JSON.parse(item.data.text[0].style)
                : [];
            return (
                <Box
                    key={`Image-` + index}
                    component="img"
                    sx={{
                        width: 350,
                        maxWidth: "100%",
                        ...(styleOverride && {
                            ...styleOverride,
                        }),
                    }}
                    alt=""
                    src={item.data.text[0].url}
                    className="aar-image"
                />
            );
        }

        case "heading1": {
            return <Heading1 key={`sectionTitle-` + index} {...item} />;
        }

        case "text": {
            return <BasicText key={`text-` + index} {...item} />;
        }

        case "horizontalList": {
            return <HorizontalList key={`horizontalList-` + index} {...item} />;
        }

        case "unorderedList": {
            return <UnorderedList key={`unorderedList-` + index} {...item} />;
        }

        case "SoftwareApplications": {
            return (
                <SoftwareApplicationsTable
                    key={`softwareApplications-` + index}
                    {...item}
                />
            );
        }

        case "PerformanceIndicators": {
            return (
                <PerformanceIndicatorsTable
                    key={`performanceIndicators-` + index}
                    {...item}
                />
            );
        }

        case "AssessmentOverview": {
            return (
                <AssessmentOverviewTable
                    key={`assessmentOverview-` + index}
                    {...item}
                />
            );
        }

        case "FocusArea": {
            return <FocusAreaTable key={`focusArea-` + index} {...item} />;
        }

        case "PatientEngagement": {
            return (
                <PatientEngagementTable
                    key={`patientEngagement-` + index}
                    {...item}
                />
            );
        }

        case "Opportunities": {
            return (
                <OpportunitiesTable key={`opportunities-` + index} {...item} />
            );
        }

        case "DataCapture": {
            return <DataCaptureTable key={`dataCapture-` + index} {...item} />;
        }

        case "ColorLegend": {
            return <ColorLegendTable key={`colorLegend-` + index} {...item} />;
        }

        case "ColorLegend2": {
            return <ColorLegend2Table key={`colorLegend2-` + index} {...item} />;
        }

        case "mui-gauge": {
            return (
                <Gauge 
                    width={120} 
                    height={120} 
                    value={item.data?.rowData[0]?.value} 
                    key={`muiGauge-${index}`}
                    sx={(theme) => ({
                        [`& .${gaugeClasses.valueArc}`]: {
                          fill: '#3cd5af',
                        },
                        [`& .${gaugeClasses.referenceArc}`]: {
                          fill: '#ff5a5a',
                        },
                      })}
                    text={
                        (value) => `${value.value?.toLocaleString()}%`
                    }
                />
            ); 
        }

        case "StagelessOpportunities": {
            return <StagelessOpportunitiesTable key={`stagelessOpportunities-${index}`} {...item} />
        }

        default: {
            if (preStyledHeadings[item.type]) {
                return <HeadingWithPreStyling key={`sectionTitle-` + index} {...item} />;
            }

            if (preStyledImages[item.type]) {
                return <ImageWithPreStyling key={`sectionImage-` + index} {...item} />
            }
            break;
        }
    }
};
