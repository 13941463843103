export const preStyledHeadings: { [key: string]: object } = {
    AAR_TitlePage_ModelName: { color: "#FF5A5A", marginBottom: "5px", marginTop: "30px" },
    AAR_TitlePage_ModelDescription: { color: "#FF5A5A", marginTop: "0px", marginBottom: "30px" },
    AAR_TitlePage_HospitalName: { color: "#000000", marginBottom: "5px", marginTop: "40px", fontSize: "30px" },
    AAR_TitlePage_HealthSystemName: { color: "#000000", marginTop: "0px", fontSize: "24px", marginBottom: "20px" },
    AAR_TitlePage_Region: { color: "#000000", marginBottom: "5px" },
    AAR_TitlePage_Country: { color: "#000000", marginTop: "0px", marginBottom: "100px" },
    AAR_TitlePage_PreparedBy: { color: "#000000", fontSize: "1em", marginBottom: "10px" },
    AAR_TitlePage_AssessmentDate: { color: "#000000", fontSize: "1em", marginBottom: "10px" },
    AAR_TitlePage_ReportDate: { color: "#000000", fontSize: "1em", marginBottom: "10px", pageBreakAfter: "always" },
    AAR_Page_Title: { fontSize: "1.3em", marginBottom: "8px", marginTop: "16px", color: "#1e22aa", fontWeight: "700", pageBreakBefore: "always" },
    AAR_VerticalList_Heading: { fontSize: "1.3em", marginBottom: "8px", marginTop: "16px", color: "#1e22aa", fontWeight: "700" },
    AAR_VerticalList_Text: { fontSize: "1.3em", marginBottom: "8px", marginTop: "16px", color: "#1e22aa", fontWeight: "700" },
    AAR_HorizontalList_Heading: { fontSize: "1.3em", marginBottom: "8px", marginTop: "16px", color: "#1e22aa", fontWeight: "700" },
    AAR_HorizontalList_Text: { fontSize: "1.3em", marginBottom: "8px", marginTop: "16px", color: "#1e22aa", fontWeight: "700" },
    AAR_Section_Heading: { fontSize: "1.3em", marginBottom: "8px", marginTop: "16px", color: "#1e22aa", fontWeight: "700" },
    AAR_Version_Label: { fontSize: "7.5px", margin: "0px 0px 20px;" },
}

export const preStyledImages: { [key: string]: object } = {
    AAR_TitlePage_ModelLogo: { width: "300px", marginBottom: "20px" },
    AAR_TitlePage_HIMSSLogo: { width: "150px" },
    AAR_ModelDescription_Image: { width: "36em", marginTop: "50px", marginBottom: "50px" },
}





