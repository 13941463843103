
// C-COMM r.104 g.5 b.209
export const GlobalColors = [
    "#55C1E9",
    "#21145F",
    "#FC7506",
    "#FFc72c",
    "#C40808",
    "#F93D3D",
    "#FF54A2",
    "#048A66",
    "#3CD5AF",
    "#D064CD",
    "#6805D1"
];

export interface Indexable {
    [key: string] : string
}

export const ModelColors:Indexable = {
    "EMRAM": GlobalColors[0], 
    "EMRAM 2022": GlobalColors[1],
    "INFRAM 2024": GlobalColors[2],
    "INFRAM": GlobalColors[3],
    "AMAM 2024": GlobalColors[4],
    "AMAM": GlobalColors[5],
    "CCMM": GlobalColors[6],
    "O-EMRAM": GlobalColors[7],
    "DIAM": GlobalColors[8],
    "CISOM": GlobalColors[9],
    "C-COMM": GlobalColors[10]
}
