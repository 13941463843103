import { PrintHeader } from "./PrintHeader.component";
import { PrintFooter } from "./PrintFooter.component";

interface PrintWrapperProps {
    children?: React.ReactNode;
}

//remove styles from css file and move into here, convert to MUI comp
export const PrintWrapper = (props: PrintWrapperProps) => {
    return (
        <table id="printWrapper" style={{ margin: "0 auto", width: '100%'}}>
            <thead style={{height: "75px"}}>
                <tr>
                    <th id="printHeader">
                        <PrintHeader />
                    </th>
                </tr>
            </thead>

            <tfoot style={{height: "50px",}}>
                <tr>
                    <th id="printFooter">
                        <PrintFooter />
                    </th>
                </tr>
            </tfoot>

            <tbody id="printBody" style={{}}>
                <tr>
                    <td>{props.children}</td>
                </tr>
            </tbody>
        </table>
    );
};
