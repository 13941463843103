import { Box, Typography } from "@mui/material";

export function PrintFooter() {
    return (
        <Box
            id={"pageFooter"}
            sx={{
                display: "none",
                width: "100%",
                background: "#1e22aa",
                borderBottom: "5px solid #55c1e9",
                height: "30px",
                "@media print": {
                    display: "block",
                    position: "fixed",
                    left: "0px",
                    bottom: "0px",
                },
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    color: "#fff",
                    height: "25px",
                    padding: "0px 20px",
                    justifyContent: "space-between",
                }}
            >
                <Box sx={{ width: "33%" }}></Box>
                <Box sx={{ width: "33%" }}>
                    <Typography sx={{ fontSize: "10px" }}>
                        ©2024 HIMSS. All Rights Reserved.
                    </Typography>
                </Box>
                <Box sx={{ width: "33%" }}>&nbsp;</Box>
            </Box>
        </Box>
    );
}
